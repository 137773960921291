import { useTranslation } from "react-i18next";
import { Spinner } from "@material-tailwind/react";

const Loading = () => {
    const { t } = useTranslation();
    return (
        <div class="w-full h-screen bg-black flex flex-col justify-center items-center">
            <Spinner class="w-16 h-16 text-white" />
            <p class="text-2xl titles font-bold text-white mt-4">{t("loading")}</p>
        </div>
    );
};

export default Loading;
