import anime from "animejs/lib/anime.es.js";

export default function FadeInAnimation() {
    const elements = document.querySelectorAll(".fade-in");

    elements.forEach((element) => {
        const options = {
            targets: element,
            opacity: [0, 1],
            easing: "easeInOutSine",
            duration: 500,
            threshold: 0.1,
        };

        const observer = new IntersectionObserver((entries) => {
            entries.forEach((entry) => {
                if (entry.isIntersecting) {
                    if (!element.dataset.animated) {
                        anime(options);
                        element.dataset.animated = true;
                    }
                }
            });
        });

        observer.observe(element);
    });
}
