import Information from "../Information";
import r1 from "../../images/r1.webp";
import r2 from "../../images/r2.webp";
import r3 from "../../images/r3.webp";
import r4 from "../../images/r4.webp";
import r5 from "../../images/r5.webp";
import { faAnglesRight } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import Fade from "../Fade";
import { useTranslation } from "react-i18next";
import Footer from "../Footer";

export default function Restaurant() {
    const { t } = useTranslation();
    return (
        <>
            <div className="w-auto h-auto flex flex-col">
                {/* Unterkunft 1 */}
                <div className="wood-1 relative w-full h-auto flex flex-col z-20">
                    <p className="titles text-white text-2xl sm:mobileLandscape:text-2xl tabletPortrait:text-3xl lg:text-3xl p-8 sm:mobileLandscape:p-8 tabletPortrait:px-16 lg:px-80 text-center drop-shadow-lg mt-8 sm:mobileLandscape:mt-8 tabletPortrait:mt-8 lg:mt-8 z-10">
                        {t("restaurant-title")}
                    </p>
                    <div className="w-full h-auto lg:min-h-screen bigDesktop:max-w-[1925px] bigDesktop:max-h-[1085px] bigDesktop:m-auto flex flex-col">
                        <div className="w-auto h-auto flex flex-col-reverse sm:mobileLandscape:flex-col tabletPortrait:flex-col-reverse lg:flex-col">
                            <div className="w-full h-auto flex flex-col sm:mobileLandscape:flex-col-reverse tabletPortrait:flex-col lg:flex-col-reverse justify-center items-start mt-8 sm:mobileLandscape:mt-0 tabletPortrait:mt-8 lg:mt-0 mb-20 sm:mobileLandscape:mb-0 tabletPortrait:mb-20 lg:mb-24">
                                <div className="w-full h-auto flex flex-row justify-start items-center px-8 sm:mobileLandscape:px-8 sm:mobileLandscape:mt-16 tabletPortrait:px-16 lg:hidden">
                                    <FontAwesomeIcon
                                        className="w-5 sm:mobileLandscape:w-5 tabletPortrait:w-5 h-5 sm:mobileLandscape:h-5 tabletPortrait:h-5 text-white drop-shadow-lg"
                                        icon={faAnglesRight}
                                    />
                                </div>
                                <div className="w-full h-auto flex flex-col justify-start items-center bigDesktop:items-start">
                                    <Fade>
                                        <p className="text-font text-white text-sm sm:mobileLandscape:text-sm tabletPortrait:text-base lg:text-base font-bold px-8 sm:mobileLandscape:pl-8 sm:mobileLandscape:pr-32 tabletPortrait:pl-16 tabletPortrait:pr-44 lg:pl-36 lg:pr-96 bigDesktop:pr-[55rem] drop-shadow-lg mt-8 sm:mobileLandscape:mt-8 tabletPortrait:mt-20 lg:mt-20 desktop:mt-32 bigDesktop:mt-40 opacity-0 fade-in">
                                            {t("restaurant-text1")}
                                        </p>
                                        <p className="text-font text-white text-sm sm:mobileLandscape:text-sm tabletPortrait:text-base lg:text-base font-bold px-8 sm:mobileLandscape:pl-8 sm:mobileLandscape:pr-32 tabletPortrait:pl-16 tabletPortrait:pr-44 lg:pl-36 lg:pr-96 bigDesktop:pr-[55rem] drop-shadow-lg mt-6 sm:mobileLandscape:mt-6 tabletPortrait:mt-6 lg:mt-6 opacity-0 fade-in">
                                            {t("restaurant-text2")}
                                        </p>
                                        <p className="text-font text-white text-sm sm:mobileLandscape:text-sm tabletPortrait:text-base lg:text-base font-bold px-8 sm:mobileLandscape:pl-8 sm:mobileLandscape:pr-32 tabletPortrait:pl-16 tabletPortrait:pr-44 lg:pl-36 lg:pr-96 bigDesktop:pr-[55rem] drop-shadow-lg mt-6 sm:mobileLandscape:mt-6 tabletPortrait:mt-6 lg:mt-6 opacity-0 fade-in">
                                            {t("restaurant-text3")}
                                        </p>
                                        <p className="text-font text-white text-sm sm:mobileLandscape:text-sm tabletPortrait:text-base lg:text-base font-bold px-8 sm:mobileLandscape:pl-8 sm:mobileLandscape:pr-32 tabletPortrait:pl-16 tabletPortrait:pr-44 lg:pl-36 lg:pr-96 bigDesktop:pr-[55rem] drop-shadow-lg mt-6 sm:mobileLandscape:mt-6 tabletPortrait:mt-6 lg:mt-6 opacity-0 fade-in"></p>
                                    </Fade>
                                </div>
                            </div>
                            <div className="max-w-96 sm:mobileLandscape:max-w-full tabletPortrait:max-w-full lg:max-w-[95%] h-auto mx-8 sm:mobileLandscape:mx-8 tabletPortrait:mx-16 lg:mx-auto mt-8 sm:mobileLandscape:mt-8 tabletPortrait:mt-8 lg:mt-0 desktop:mt-16 flex flex-row overflow-x-scroll sm:mobileLandscape:mb-8 tabletPortrait:mb-0 lg:mb-8">
                                <img
                                    className="room-img h-44 sm:mobileLandscape:h-48 tabletPortrait:h-48 lg:h-44 desktop:h-52 w-auto mr-8 sm:mobileLandscape:mr-8 tabletPortrait:mr-8 lg:mr-8"
                                    src={r1}
                                    alt="Restaurant 1"
                                    placeholder="blur"
                                />
                                <img
                                    className="room-img h-44 sm:mobileLandscape:h-48 tabletPortrait:h-48 lg:h-44 desktop:h-52 w-auto mt-8 sm:mobileLandscape:mt-8 tabletPortrait:mt-8 lg:mt-8 mr-8 sm:mobileLandscape:mr-8 tabletPortrait:mr-8 lg:mr-8"
                                    src={r2}
                                    alt="Restaurant 2"
                                    placeholder="blur"
                                />
                                <img
                                    className="room-img h-44 sm:mobileLandscape:h-48 tabletPortrait:h-48 lg:h-44 desktop:h-52 w-auto mr-8 sm:mobileLandscape:mr-8 tabletPortrait:mr-8 lg:mr-8"
                                    src={r3}
                                    alt="Restaurant 3"
                                    placeholder="blur"
                                />
                                <img
                                    className="room-img h-44 sm:mobileLandscape:h-48 tabletPortrait:h-48 lg:h-44 desktop:h-52 w-auto mt-8 sm:mobileLandscape:mt-8 tabletPortrait:mt-8 lg:mt-8 mr-8 sm:mobileLandscape:mr-8 tabletPortrait:mr-8 lg:mr-8"
                                    src={r4}
                                    alt="Restaurant 4"
                                    placeholder="blur"
                                />
                                <img
                                    className="room-img h-44 sm:mobileLandscape:h-48 tabletPortrait:h-48 lg:h-44 desktop:h-52 w-auto"
                                    src={r5}
                                    alt="Restaurant 5"
                                    placeholder="blur"
                                />
                            </div>
                        </div>
                    </div>
                </div>

                <Information />
                <Footer />
            </div>
        </>
    );
}
