import logo_w from "../images/transparent_logo_white.webp";
import logo_b from "../images/transparent_logo_black.webp";
import poulet from "../images/poulet.webp";
import m_r from "../images/manu_romana.webp";
import z1 from "../images/z1.webp";
import z2 from "../images/z2.webp";
import Information from "./Information";
import Fade from "./Fade";
import { useTranslation } from "react-i18next";
import Footer from "./Footer";
import { Link } from "react-router-dom";

export default function Home() {
    const { t } = useTranslation();
    return (
        <>
            <div className="w-auto h-auto flex flex-col">
                {/* Startseite 1 */}
                <div className="house-bg relative w-full min-h-svh flex flex-col justify-between">
                    <div className="w-full h-10 sm:mobileLandscape:h-10 tabletPortrait:h-10 lg:h-10 flex flex-row justify-center lg:justify-center items-center mt-6 sm:mobileLandscape:mt-6 tabletPortrait:mt-6 lg:mt-6">
                        <div className="mt-32 sm:mobileLandscape:mt-0 tabletPortrait:mt-0 lg:mt-0 desktop:mt-0 bigDesktop:mt-0 w-12 sm:mobileLandscape:w-12 tabletPortrait:w-14 lg:w-14 h-12 sm:mobileLandscape:h-12 tabletPortrait:h-14 lg:h-14 mr-4 sm:mobileLandscape:mr-8 tabletPortrait:mr-8 lg:mr-4">
                            <img className="drop-shadow-lg" src={logo_b} alt="Gasthaus Ochsen Brunnen" />
                        </div>
                        <p className="titles text-black text-2xl mt-32 sm:mobileLandscape:mt-0 tabletPortrait:mt-0 lg:mt-0 desktop:mt-0 bigDesktop:mt-0 sm:mobileLandscape:text-2xl tabletPortrait:text-3xl lg:text-3xl text-center drop-shadow-lg">
                            {t("home-title")}
                        </p>
                    </div>
                    <Fade>
                        <p className="titles text-white text-2xl sm:mobileLandscape:text-2xl tabletPortrait:text-3xl lg:text-3xl p-8 pt-0 sm:mobileLandscape:p-8 sm:mobileLandscape:pt-0 tabletPortrait:p-16 tabletPortrait:pt-0 tabletPortrait:pb-8 lg:p-16 lg:pt-0 lg:pb-8 text-center drop-shadow-lg blend-effect opacity-0 fade-in">
                            {t("home-subtitle1")}
                            <br />
                            {t("home-subtitle2")}
                        </p>
                    </Fade>
                </div>

                {/* Startseite 2 */}
                <div className="wood-1 relative w-full h-auto lg:min-h-screen flex flex-col">
                    <Fade>
                        <p className="titles text-white text-2xl sm:mobileLandscape:text-2xl tabletPortrait:text-3xl lg:text-3xl p-8 sm:mobileLandscape:px-32 tabletPortrait:px-16 lg:px-80 text-center drop-shadow-lg mt-8 sm:mobileLandscape:mt-8 tabletPortrait:mt-8 lg:mt-8 z-10 opacity-0 fade-in">
                            {t("home-poulet-title")}
                        </p>
                    </Fade>
                    <div className="w-full h-auto bigDesktop:max-w-[1925px] bigDesktop:max-h-[1085px] bigDesktop:m-auto flex flex-col sm:mobileLandscape:flex-row tabletPortrait:flex-col lg:flex-row m-auto">
                        <div className="w-64 sm:mobileLandscape:w-3/5 tabletPortrait:w-3/6 lg:w-3/5 h-64 sm:mobileLandscape:h-4/5 tabletPortrait:h-3/6 lg:h-3/5 mt-8 sm:mobileLandscape:mt-8 tabletPortrait:mt-8 lg:mt-8 desktop:mt-20 bigDesktop:mt-28 ml-8 sm:mobileLandscape:ml-8 tabletPortrait:ml-16 lg:ml-36 sm:mobileLandscape:mb-8 lg:mb-20 drop-shadow-lg">
                            <img
                                className="poulet-img rounded-bl-[50px] rounded-tr-[50px]"
                                src={poulet}
                                alt="Poulet"
                                placeholder="blur"
                            />
                        </div>
                        <div className="w-full h-auto flex flex-col justify-center items-start sm:mobileLandscape:pl-8 tabletPortrait:px-8 lg:pl-24 desktop:pl-40 bigDesktop:pl-52">
                            <Fade>
                                <p className="titles text-white text-lg sm:mobileLandscape:text-lg tabletPortrait:text-2xl lg:text-2xl px-8 sm:mobileLandscape:px-8 tabletPortrait:px-8 lg:pl-8 lg:pr-36 drop-shadow-lg mt-0 sm:mobileLandscape:mt-8 tabletPortrait:mt-16 lg:mt-8 opacity-0 fade-in">
                                    <Link to="/chicken">
                                        {t("home-poulet-subtitle")} -{" "}
                                        <span className="underline underline-offset-8 text-white">Poul</span>et im
                                        Chörbli
                                    </Link>
                                </p>
                                <p className="text-font text-white text-sm sm:mobileLandscape:text-sm tabletPortrait:text-base lg:text-base font-bold px-8 sm:mobileLandscape:px-8 tabletPortrait:pl-8 lg:pl-8 tabletPortrait:pr-36 lg:pr-36 bigDesktop:pr-52 drop-shadow-lg mt-6 sm:mobileLandscape:mt-6 tabletPortrait:mt-6 lg:mt-6 bigDesktop:mt-12 opacity-0 fade-in">
                                    {t("home-poulet-text1")}
                                </p>
                                <p className="text-font text-white text-sm sm:mobileLandscape:text-sm tabletPortrait:text-base lg:text-base font-bold px-8 sm:mobileLandscape:px-8 tabletPortrait:pl-8 lg:pl-8 tabletPortrait:pr-36 lg:pr-36 bigDesktop:pr-52 drop-shadow-lg mt-3 sm:mobileLandscape:mt-3 tabletPortrait:mt-3 lg:mt-3 opacity-0 fade-in">
                                    {t("home-poulet-text2")}
                                </p>
                                <p className="titles text-white text-sm sm:mobileLandscape:text-sm tabletPortrait:text-lg lg:text-lg font-bold px-8 sm:mobileLandscape:px-8 tabletPortrait:px-8 lg:px-8 drop-shadow-lg mt-6 sm:mobileLandscape:mt-6 tabletPortrait:mt-6 lg:mt-6 mb-20 sm:mobileLandscape:mb-20 tabletPortrait:mb-20 lg:mb-20 opacity-0 fade-in">
                                    <Link to="/menu">
                                        <span className="underline underline-offset-8 text-white">
                                            {t("home-poulet-link1")}
                                        </span>
                                        {t("home-poulet-link2")}
                                    </Link>
                                </p>
                            </Fade>
                        </div>
                    </div>
                </div>

                {/* Startseite 3 */}
                <div className="relative w-full h-auto lg:min-h-screen flex flex-col">
                    <Fade>
                        <p className="titles text-white text-2xl sm:mobileLandscape:text-2xl tabletPortrait:text-3xl lg:text-3xl p-8 sm:mobileLandscape:p-8 tabletPortrait:px-16 lg:px-80 text-center drop-shadow-lg my-8 sm:mobileLandscape:my-8 tabletPortrait:my-8 lg:my-8 z-10 opacity-0 fade-in">
                            {t("home-ochsen-title1")}
                            <br />
                            {t("home-ochsen-title2")}
                        </p>
                    </Fade>
                    <div className="w-full h-auto bigDesktop:max-w-[1925px] bigDesktop:max-h-[1085px] bigDesktop:m-auto flex flex-col sm:mobileLandscape:flex-row tabletPortrait:flex-col lg:flex-row">
                        <div className="w-full h-auto flex flex-col justify-center items-start">
                            <Fade>
                                <div className="w-auto h-auto flex flex-row mx-8 sm:mobileLandscape:mx-8 tabletPortrait:mx-16 lg:ml-36 justify-center items-center opacity-0 fade-in">
                                    <div className="w-10 sm:mobileLandscape:w-10 tabletPortrait:w-14 lg:w-14 h-10 sm:mobileLandscape:h-10 tabletPortrait:h-14 lg:h-14 mr-2 sm:mobileLandscape:mr-2 tabletPortrait:mr-4 lg:mr-4">
                                        <img className="drop-shadow-lg" src={logo_w} alt="GOB Logo" />
                                    </div>
                                    <p className="titles text-white text-lg sm:mobileLandscape:text-lg tabletPortrait:text-2xl lg:text-2xl drop-shadow-lg">
                                        {t("home-ochsen-subtitle")}
                                    </p>
                                </div>
                                <p className="text-font text-white text-sm sm:mobileLandscape:text-sm tabletPortrait:text-base lg:text-base font-bold px-8 sm:mobileLandscape:px-8 tabletPortrait:pl-16 tabletPortrait:pr-44 lg:pl-36 lg:pr-8 bigDesktop:pr-52 drop-shadow-lg mt-6 sm:mobileLandscape:mt-6 tabletPortrait:mt-6 lg:mt-6 bigDesktop:mt-12 opacity-0 fade-in">
                                    {t("home-ochsen-text1")}
                                </p>
                                <p className="text-font text-white text-sm sm:mobileLandscape:text-sm tabletPortrait:text-base lg:text-base font-bold px-8 sm:mobileLandscape:px-8 tabletPortrait:pl-16 tabletPortrait:pr-44 lg:pl-36 lg:pr-8 bigDesktop:pr-52 drop-shadow-lg mt-3 sm:mobileLandscape:mt-3 tabletPortrait:mt-3 lg:mt-3 opacity-0 fade-in">
                                    {t("home-ochsen-text2")}
                                </p>
                                <p className="titles text-white text-sm sm:mobileLandscape:text-sm tabletPortrait:text-lg lg:text-lg font-bold px-8 sm:mobileLandscape:px-8 tabletPortrait:px-16 lg:pl-36 lg:pr-8 drop-shadow-lg mt-6 sm:mobileLandscape:mt-6 tabletPortrait:mt-6 lg:mt-6 mb-10 sm:mobileLandscape:mb-20 tabletPortrait:mb-20 lg:mb-20 opacity-0 fade-in">
                                    <Link to="/restaurant">
                                        <span className="underline underline-offset-8 text-white">
                                            {t("home-ochsen-link1")}
                                        </span>
                                        {t("home-ochsen-link2")}
                                    </Link>
                                </p>
                            </Fade>
                        </div>
                        <div className="relative w-auto sm:mobileLandscape:w-9/12 tabletPortrait:w-5/6 lg:w-5/6 h-72 sm:mobileLandscape:h-96 tabletPortrait:h-[32rem] lg:h-[36rem] desktop:h-[45rem] bigDesktop:h-[50rem] lg:mt-24 flex flex-row justify-center items-end mx-8 sm:mobileLandscape:ml-0 sm:mobileLandscape:mr-8 tabletPortrait:mx-auto lg:mr-8 bigDesktop:mr-28 overflow-hidden">
                            <div className="absolute w-72 sm:mobileLandscape:w-72 tabletPortrait:w-[32rem] lg:w-5/6 h-auto z-0 shadow-lg">
                                <img src={m_r} alt="Manuel und Romana" placeholder="blur" />
                            </div>
                        </div>
                    </div>
                </div>

                {/* Startseite 4 */}
                <div className="wood-1 first-letter:relative w-full h-auto lg:min-h-screen flex flex-col z-20">
                    <Fade>
                        <p className="titles text-white text-2xl sm:mobileLandscape:text-2xl tabletPortrait:text-3xl lg:text-3xl p-8 sm:mobileLandscape:px-32 tabletPortrait:px-16 lg:px-80 text-center drop-shadow-lg mt-8 sm:mobileLandscape:mt-8 tabletPortrait:mt-8 lg:mt-8 z-10 opacity-0 fade-in">
                            {t("home-hotel-title")}
                        </p>
                    </Fade>
                    <div className="w-full h-auto bigDesktop:max-w-[1925px] bigDesktop:max-h-[1085px] bigDesktop:m-auto flex flex-col sm:mobileLandscape:flex-row tabletPortrait:flex-col lg:flex-row">
                        <div className="w-full h-auto flex flex-col justify-center items-start">
                            <Fade>
                                <p className="titles text-white text-lg sm:mobileLandscape:text-lg tabletPortrait:text-2xl lg:text-2xl px-8 sm:mobileLandscape:px-8 tabletPortrait:px-16 lg:pl-36 lg:pr-8 drop-shadow-lg mt-8 sm:mobileLandscape:mt-8 tabletPortrait:mt-8 lg:mt-8 opacity-0 fade-in">
                                    {t("home-hotel-subtitle")}
                                </p>
                                <p className="text-font text-white text-sm sm:mobileLandscape:text-sm tabletPortrait:text-base lg:text-base font-bold px-8 sm:mobileLandscape:px-8 tabletPortrait:pl-16 tabletPortrait:pr-44 lg:pl-36 lg:pr-28 desktop:pr-56 bigDesktop:pr-[28rem] drop-shadow-lg mt-6 sm:mobileLandscape:mt-6 tabletPortrait:mt-6 lg:mt-6 bigDesktop:mt-12 opacity-0 fade-in">
                                    {t("home-hotel-text1")}
                                </p>
                                <p className="text-font text-white text-sm sm:mobileLandscape:text-sm tabletPortrait:text-base lg:text-base font-bold px-8 sm:mobileLandscape:px-8 tabletPortrait:pl-16 tabletPortrait:pr-44 lg:pl-36 lg:pr-28 desktop:pr-56 bigDesktop:pr-[28rem] drop-shadow-lg mt-3 sm:mobileLandscape:mt-3 tabletPortrait:mt-3 lg:mt-3 opacity-0 fade-in">
                                    {t("home-hotel-text2")}
                                </p>
                                <p className="titles text-white text-sm sm:mobileLandscape:text-sm tabletPortrait:text-lg lg:text-lg font-bold px-8 sm:mobileLandscape:px-8 tabletPortrait:px-16 lg:pl-36 lg:pr-8 drop-shadow-lg mt-6 sm:mobileLandscape:mt-6 tabletPortrait:mt-6 lg:mt-6 mb-10 sm:mobileLandscape:mb-20 tabletPortrait:mb-20 lg:mb-20 opacity-0 fade-in">
                                    <Link to="/hotel">
                                        <span className="underline underline-offset-8 text-white">
                                            {t("home-hotel-link1")}
                                        </span>
                                        {t("home-hotel-link2")}
                                    </Link>
                                </p>
                            </Fade>
                        </div>
                        <div className="relative max-w-96 sm:mobileLandscape:w-[32rem] tabletPortrait:w-[32rem] lg:min-w-[28rem] bigDesktop:min-w-[32rem] h-96 sm:mobileLandscape:h-[20rem] tabletPortrait:h-[32rem] lg:h-[32rem] bigDesktop:h-[36rem] mx-8 sm:mobileLandscape:ml-0 sm:mobileLandscape:mr-8 tabletPortrait:mx-auto lg:ml-0 lg:mr-36 bigDesktop:mr-48 mb-20 sm:mobileLandscape:mb-8 sm:mobileLandscape:mt-8 lg:mt-8 desktop:mt-32 bigDesktop:mt-40 tabletPortrait:mb-20 lg:mb-20 overflow-hidden">
                            <div className="absolute w-56 sm:mobileLandscape:w-52 tabletPortrait:w-72 lg:w-72 desktop:w-72 bigDesktop:w-[22rem] h-auto z-10 drop-shadow-lg top-12 sm:mobileLandscape:top-10 tabletPortrait:top-20 lg:top-20 right-5 sm:mobileLandscape:right-2 tabletPortrait:right-0 lg:right-2 desktop:right-0">
                                <img
                                    className="room-img rounded-bl-[50px] rounded-tr-[50px]"
                                    src={z2}
                                    alt="Zimmer 2"
                                    placeholder="blur"
                                />
                            </div>
                            <div className="absolute w-56 sm:mobileLandscape:w-52 tabletPortrait:w-72 lg:w-72 desktop:w-72 bigDesktop:w-[22rem] h-auto z-0 drop-shadow-lg bottom-14 sm:mobileLandscape:bottom-7 tabletPortrait:bottom-20 lg:bottom-20 left-5 sm:mobileLandscape:left-2 tabletPortrait:left-0 lg:left-2 desktop:left-0">
                                <img
                                    className="room-img rounded-br-[50px] rounded-tl-[50px]"
                                    src={z1}
                                    alt="Zimmer 1"
                                    placeholder="blur"
                                />
                            </div>
                        </div>
                    </div>
                </div>

                <Information />
                <Footer />
            </div>
        </>
    );
}
