import Information from "../Information";
import z1 from "../../images/z1.webp";
import z2 from "../../images/z2.webp";
import z3 from "../../images/z3.webp";
import { faAnglesRight } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import Fade from "../Fade";
import { useTranslation } from "react-i18next";
import Footer from "../Footer";

export default function Hotel() {
    const { t } = useTranslation();
    return (
        <>
            <div className="w-auto h-auto flex flex-col">
                {/* Unterkunft 1 */}
                <div className="wood-1 relative w-full h-auto lg:min-h-screen flex flex-col z-20">
                    <p className="titles text-white text-2xl sm:mobileLandscape:text-2xl tabletPortrait:text-3xl lg:text-3xl p-8 sm:mobileLandscape:p-8 tabletPortrait:px-16 lg:px-80 text-center drop-shadow-lg mt-8 sm:mobileLandscape:mt-8 tabletPortrait:mt-8 lg:mt-8 z-10">
                        {t("hotel-title")}
                    </p>
                    <div className="w-full h-auto bigDesktop:max-w-[1925px] bigDesktop:max-h-[1085px] bigDesktop:m-auto flex flex-col">
                        <div className="w-full h-auto flex flex-col sm:mobileLandscape:flex-col tabletPortrait:flex-col lg:flex-row">
                            <div className="max-w-96 sm:mobileLandscape:max-w-full tabletPortrait:max-w-full lg:min-w-[28rem] h-auto mx-8 sm:mobileLandscape:mx-16 tabletPortrait:mx-16 lg:ml-36 lg:mr-8 mb-8 sm:mobileLandscape:mb-8 tabletPortrait:mb-16 lg:mb-20 mt-8 sm:mobileLandscape:mt-8 tabletPortrait:mt-8 lg:mt-28 flex flex-row lg:flex-col overflow-x-scroll bigDesktop:ml-64">
                                <img
                                    className="room-img h-52 sm:mobileLandscape:h-44 tabletPortrait:h-56 lg:h-auto lg:w-64 bigDesktop:w-80 w-auto mr-8 sm:mobileLandscape:mr-8 tabletPortrait:mr-8 lg:mr-0"
                                    src={z1}
                                    alt="Zimmer 1"
                                    placeholder="blur"
                                />
                                <img
                                    className="room-img h-52 sm:mobileLandscape:h-44 tabletPortrait:h-56 lg:h-auto lg:w-64 bigDesktop:w-80 w-auto mt-8 sm:mobileLandscape:mt-8 tabletPortrait:mt-8 lg:my-8 mr-8 sm:mobileLandscape:mr-8 tabletPortrait:mr-8 lg:ml-36"
                                    src={z2}
                                    alt="Zimmer 2"
                                    placeholder="blur"
                                />
                                <img
                                    className="room-img h-52 sm:mobileLandscape:h-44 tabletPortrait:h-56 lg:h-auto lg:w-64 bigDesktop:w-80 w-auto"
                                    src={z3}
                                    alt="Zimmer 3"
                                    placeholder="blur"
                                />
                            </div>
                            <div className="w-full h-auto flex flex-col sm:mobileLandscape:flex-row tabletPortrait:flex-row lg:flex-col justify-center items-start sm:mobileLandscape:mx-auto tabletPortrait:mx-auto lg:mx-auto lg:mt-12">
                                <div className="w-auto h-auto flex flex-col mobileLandscape:pl-8 desktop:pl-16 bigDesktop:pl-52">
                                    <div className="w-full h-auto flex flex-row justify-between items-center px-8 sm:mobileLandscape:px-8 tabletPortrait:px-16 lg:pl-36">
                                        <Fade>
                                            <p className="titles text-white text-lg sm:mobileLandscape:text-lg tabletPortrait:text-2xl lg:text-2xl drop-shadow-lg opacity-0 fade-in">
                                                {t("hotel-subtitle")}
                                            </p>
                                        </Fade>
                                        <FontAwesomeIcon
                                            className="w-5 h-5 text-white drop-shadow-lg sm:mobileLandscape:hidden tabletPortrait:hidden lg:hidden"
                                            icon={faAnglesRight}
                                        />
                                    </div>
                                    <Fade>
                                        <p className="text-font text-white text-sm sm:mobileLandscape:text-sm tabletPortrait:text-base lg:text-base font-bold px-8 sm:mobileLandscape:pl-8 sm:mobileLandscape:pr-16 tabletPortrait:pl-16 tabletPortrait:pr-32 lg:pl-36 lg:pr-36 drop-shadow-lg mt-6 sm:mobileLandscape:mt-6 tabletPortrait:mt-6 lg:mt-6 bigDesktop:mt-12 sm:mobileLandscape:mb-8 tabletPortrait:mb-8 lg:mb-8 opacity-0 fade-in">
                                            {t("hotel-text1")}
                                            <br />
                                            <br />
                                            {t("hotel-text2")}
                                            <br />
                                            {t("hotel-text3")}
                                            <br />
                                            <br />
                                            {t("hotel-text4")}
                                            <br />
                                            {t("hotel-text5")}
                                            <br />
                                            {t("hotel-text6")}
                                            <br />
                                            {t("hotel-text7")}
                                            <br />
                                            {t("hotel-text8")}
                                            <br />
                                            <br />
                                            {t("hotel-text9")}
                                            <br />
                                            {t("hotel-text10")}
                                        </p>
                                    </Fade>
                                </div>
                                <div className="w-auto h-auto flex flex-col pr-8 desktop:pl-16 bigDesktop:pl-52">
                                    <Fade>
                                        <p className="titles text-white text-lg sm:mobileLandscape:text-lg tabletPortrait:text-2xl lg:text-2xl px-8 sm:mobileLandscape:px-8 tabletPortrait:pr-16 lg:pl-36 drop-shadow-lg mt-8 sm:mobileLandscape:mt-0 tabletPortrait:mt-0 lg:mt-0 opacity-0 fade-in">
                                            {t("hotel-subtitle2")}
                                        </p>
                                        <ul className="text-font text-white text-sm sm:mobileLandscape:text-sm tabletPortrait:text-base lg:text-base font-bold pr-8 sm:mobileLandscape:pr-8 tabletPortrait:pr-16 lg:pl-40 drop-shadow-lg mb-20 sm:mobileLandscape:mb-0 tabletPortrait:mb-0 lg:mb-16 list-disc pl-12 sm:mobileLandscape:pl-12 tabletPortrait:pl-12">
                                            <li className="mt-6 sm:mobileLandscape:mt-6 tabletPortrait:mt-6 lg:mt-6 bigDesktop:mt-12 mb-1 sm:mobileLandscape:mb-1 tabletPortrait:mb-1 lg:mb-1 opacity-0 fade-in">
                                                {t("hotel-item1")}
                                            </li>
                                            <li className="mb-1 sm:mobileLandscape:mb-1 tabletPortrait:mb-1 lg:mb-1 opacity-0 fade-in">
                                                {t("hotel-item2")}
                                            </li>
                                            <li className="mb-1 sm:mobileLandscape:mb-1 tabletPortrait:mb-1 lg:mb-1 opacity-0 fade-in">
                                                {t("hotel-item3")}
                                            </li>
                                            <li className="mb-1 sm:mobileLandscape:mb-1 tabletPortrait:mb-1 lg:mb-1 opacity-0 fade-in">
                                                {t("hotel-item4")}
                                            </li>
                                            <li className="opacity-0 fade-in">{t("hotel-item5")}</li>
                                        </ul>
                                    </Fade>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <Information />
                <Footer />
            </div>
        </>
    );
}
