import { faPhone, faUmbrellaBeach, faEnvelope } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import Fade from "../Fade";
import { useTranslation } from "react-i18next";
import Footer from "../Footer";

export default function Contact() {
    const { t } = useTranslation();
    return (
        <>
            {/* Info and Contact */}
            <div className="relative w-full h-auto flex flex-col">
                <p className="titles text-white text-2xl sm:mobileLandscape:text-2xl tabletPortrait:text-3xl lg:text-3xl p-8 sm:mobileLandscape:p-8 tabletPortrait:px-16 lg:px-80 text-center drop-shadow-lg my-8 sm:mobileLandscape:my-8 tabletPortrait:my-8 lg:my-8">
                    {t("contact-title")}
                </p>
                <div className="w-full h-auto bigDesktop:max-w-[1925px] bigDesktop:max-h-[1085px] bigDesktop:m-auto flex flex-col justify-start">
                    <div className="w-full h-auto flex flex-col justify-center items-start">
                        <Fade>
                            <p className="titles text-white text-lg sm:mobileLandscape:text-lg tabletPortrait:text-2xl lg:text-2xl px-8 sm:mobileLandscape:px-8 tabletPortrait:px-16 lg:pl-36 drop-shadow-lg opacity-0 fade-in">
                                {t("info-subtitle1")}
                            </p>
                        </Fade>
                        <div className="w-auto h-auto flex flex-col sm:mobileLandscape:flex-row tabletPortrait:flex-row lg:flex-row justify-start">
                            <div className="w-auto h-auto flex flex-col justify-start sm:mobileLandscape:mr-8 tabletPortrait:mr-8 lg:mr-8">
                                <Fade>
                                    <p className="text-font text-white text-base sm:mobileLandscape:text-base tabletPortrait:text-lg lg:text-lg font-bold px-8 sm:mobileLandscape:px-8 tabletPortrait:px-16 lg:pl-36 drop-shadow-lg mt-6 sm:mobileLandscape:mt-6 tabletPortrait:mt-6 lg:mt-6 opacity-0 fade-in">
                                        {t("info-subsubtitle1")}
                                    </p>
                                    <p className="text-font text-white text-sm sm:mobileLandscape:text-sm tabletPortrait:text-base lg:text-base font-bold px-8 sm:mobileLandscape:px-8 tabletPortrait:px-16 lg:pl-36 drop-shadow-lg mt-3 sm:mobileLandscape:mt-3 tabletPortrait:mt-3 lg:mt-3 opacity-0 fade-in">
                                        {t("info-monday")}
                                        <br />
                                        <span className="font-light">{t("info-monday-time")}</span>
                                    </p>
                                    <p className="text-font text-white text-sm sm:mobileLandscape:text-sm tabletPortrait:text-base lg:text-base font-bold px-8 sm:mobileLandscape:px-8 tabletPortrait:px-16 lg:pl-36 drop-shadow-lg mt-3 sm:mobileLandscape:mt-3 tabletPortrait:mt-3 lg:mt-3 opacity-0 fade-in">
                                        {t("info-tuesday-to-friday")}
                                        <br />
                                        <span className="font-light">{t("info-t-to-f-time1")}</span>
                                        <br />
                                        <span className="font-light">{t("info-t-to-f-time2")}</span>
                                    </p>
                                    <p className="text-font text-white text-sm sm:mobileLandscape:text-sm tabletPortrait:text-base lg:text-base font-bold px-8 sm:mobileLandscape:px-8 tabletPortrait:px-16 lg:pl-36 drop-shadow-lg mt-3 sm:mobileLandscape:mt-3 tabletPortrait:mt-3 lg:mt-3 opacity-0 fade-in">
                                        {t("info-saturday-sunday")}
                                        <br />
                                        <span className="font-light">{t("info-saturday-sunday-time")}</span>
                                    </p>
                                </Fade>
                            </div>
                            <div className="w-auto h-auto flex flex-col sm:mobileLandscape:flex-row tabletPortrait:flex-col lg:flex-row justify-start">
                                <div>
                                    <Fade>
                                        <p className="text-font text-white text-base sm:mobileLandscape:text-base tabletPortrait:text-lg lg:text-lg font-bold px-8 sm:mobileLandscape:px-8 tabletPortrait:pl-4 lg:px-16 drop-shadow-lg mt-12 sm:mobileLandscape:mt-6 tabletPortrait:mt-6 lg:mt-6 opacity-0 fade-in">
                                            {t("info-subsubtitle2")}
                                        </p>
                                        <p className="text-font text-white text-sm sm:mobileLandscape:text-sm tabletPortrait:text-base lg:text-base font-bold px-8 sm:mobileLandscape:px-8 tabletPortrait:pl-4 lg:px-16 drop-shadow-lg mt-3 sm:mobileLandscape:mt-3 tabletPortrait:mt-3 lg:mt-3 opacity-0 fade-in">
                                            {t("info-monday2")}
                                            <br />
                                            <span className="font-light">{t("info-monday-time2")}</span>
                                        </p>
                                        <p className="text-font text-white text-sm sm:mobileLandscape:text-sm tabletPortrait:text-base lg:text-base font-bold px-8 sm:mobileLandscape:px-8 tabletPortrait:pl-4 lg:px-16 drop-shadow-lg mt-3 sm:mobileLandscape:mt-3 tabletPortrait:mt-3 lg:mt-3 opacity-0 fade-in">
                                            {t("info-tues-to-thurs")}
                                            <br />
                                            <span className="font-light">{t("info-tues-to-thurs-time1")}</span>
                                            <br />
                                            <span className="font-light">{t("info-tues-to-thurs-time2")}</span>
                                        </p>
                                        <p className="text-font text-white text-sm sm:mobileLandscape:text-sm tabletPortrait:text-base lg:text-base font-bold px-8 sm:mobileLandscape:px-8 tabletPortrait:pl-4 lg:px-16 drop-shadow-lg mt-3 sm:mobileLandscape:mt-3 tabletPortrait:mt-3 lg:mt-3 opacity-0 fade-in">
                                            {t("info-friday")}
                                            <br />
                                            <span className="font-light">{t("info-friday-time1")}</span>
                                            <br />
                                            <span className="font-light">{t("info-friday-time2")}</span>
                                        </p>
                                    </Fade>
                                </div>
                                <div className="sm:mobileLandscape:mt-12 tabletPortrait:mt-0 lg:mt-12">
                                    <Fade>
                                        <p className="text-font text-white text-sm sm:mobileLandscape:text-sm tabletPortrait:text-base lg:text-base font-bold px-8 sm:mobileLandscape:px-8 tabletPortrait:pl-4 lg:px-16 drop-shadow-lg mt-3 sm:mobileLandscape:mt-3 tabletPortrait:mt-3 lg:mt-3 opacity-0 fade-in">
                                            {t("info-saturday")}
                                            <br />
                                            <span className="font-light">{t("info-saturday-time")}</span>
                                        </p>
                                        <p className="text-font text-white text-sm sm:mobileLandscape:text-sm tabletPortrait:text-base lg:text-base font-bold px-8 sm:mobileLandscape:px-8 tabletPortrait:pl-4 lg:px-16 drop-shadow-lg mt-3 sm:mobileLandscape:mt-3 tabletPortrait:mt-3 lg:mt-3 opacity-0 fade-in">
                                            {t("info-sunday")}
                                            <br />
                                            <span className="font-light">{t("info-sunday-time")}</span>
                                        </p>
                                    </Fade>
                                </div>
                            </div>
                        </div>
                        <div className="w-auto h-auto flex flex-col sm:mobileLandscape:flex-row tabletPortrait:flex-row lg:flex-row justify-start">
                            <div className="w-auto h-auto flex flex-col justify-start">
                                <Fade>
                                    <p className="titles text-white text-lg sm:mobileLandscape:text-lg tabletPortrait:text-2xl lg:text-2xl px-8 sm:mobileLandscape:px-8 tabletPortrait:px-16 lg:pl-36 drop-shadow-lg mt-12 sm:mobileLandscape:mt-12 tabletPortrait:mt-12 lg:mt-12 opacity-0 fade-in">
                                        {t("info-location-title")}
                                    </p>
                                    <p className="text-font text-white text-sm sm:mobileLandscape:text-sm tabletPortrait:text-base lg:text-base font-light px-8 sm:mobileLandscape:px-8 tabletPortrait:px-16 lg:pl-36 drop-shadow-lg mt-6 sm:mobileLandscape:mt-6 tabletPortrait:mt-6 lg:mt-6 opacity-0 fade-in">
                                        <span className="font-bold">{t("info-location-subtitle")}</span>
                                        <br />
                                        {t("info-location-address")}
                                        <br />
                                        {t("info-location-plz")}
                                        <br />
                                        {t("info-location-country")}
                                    </p>
                                </Fade>
                            </div>
                            <div className="w-auto h-auto flex flex-col justify-start">
                                <Fade>
                                    <p className="titles text-white text-lg sm:mobileLandscape:text-lg tabletPortrait:text-2xl lg:text-2xl px-8 sm:mobileLandscape:px-8 tabletPortrait:pl-16 lg:pl-16 drop-shadow-lg mt-12 sm:mobileLandscape:mt-12 tabletPortrait:mt-12 lg:mt-12 opacity-0 fade-in">
                                        {t("info-reservation-title")}
                                    </p>
                                    <div className="w-auto h-auto flex flex-row justify-start px-8 sm:mobileLandscape:px-8 tabletPortrait:pl-16 lg:px-16 items-center mt-6 sm:mobileLandscape:mt-6 tabletPortrait:mt-6 lg:mt-6 opacity-0 fade-in">
                                        <FontAwesomeIcon
                                            className="w-5 sm:mobileLandscape:w-5 tabletPortrait:w-6 lg:w-6 h-5 sm:mobileLandscape:h-5 tabletPortrait:h-6 lg:h-6 mr-3 sm:mobileLandscape:mr-3 tabletPortrait:mr-3 lg:mr-3 text-white"
                                            icon={faPhone}
                                        />
                                        <a
                                            href="tel:+41 41 820 11 59"
                                            className="whitespace-nowrap text-font text-white text-sm sm:mobileLandscape:text-sm tabletPortrait:text-base lg:text-base font-light drop-shadow-lg"
                                        >
                                            +41 41 820 11 59
                                        </a>
                                        <p className="text-font text-white text-sm sm:mobileLandscape:text-sm tabletPortrait:text-base lg:text-base font-light drop-shadow-lg ml-4">
                                            {t("info-reservation-subtitle1")}
                                        </p>
                                    </div>
                                    {/* <div className="w-auto h-auto flex flex-row justify-start px-8 sm:mobileLandscape:px-8 tabletPortrait:pl-16 lg:px-16 items-center mt-6 sm:mobileLandscape:mt-6 tabletPortrait:mt-6 lg:mt-6 opacity-0 fade-in">
                                        <FontAwesomeIcon
                                            className="w-5 sm:mobileLandscape:w-5 tabletPortrait:w-6 lg:w-6 h-5 sm:mobileLandscape:h-5 tabletPortrait:h-6 lg:h-6 mr-3 sm:mobileLandscape:mr-3 tabletPortrait:mr-3 lg:mr-3 text-white"
                                            icon={faEnvelope}
                                        />
                                        <a
                                            href="mailto:EMAIL"
                                            className="whitespace-nowrap text-font text-white text-sm sm:mobileLandscape:text-sm tabletPortrait:text-base lg:text-base font-light drop-shadow-lg"
                                        >
                                            EMAIL
                                        </a>
                                        <p className="text-font text-white text-sm sm:mobileLandscape:text-sm tabletPortrait:text-base lg:text-base font-light drop-shadow-lg ml-4">
                                            {t("info-reservation-subtitle2")}
                                        </p>
                                    </div> */}
                                </Fade>
                            </div>
                        </div>
                    </div>
                    <div className="w-auto h-auto flex flex-col justify-start">
                        <div className="w-auto h-auto flex flex-col justify-start">
                            <Fade>
                                <p className="titles text-white text-lg sm:mobileLandscape:text-lg tabletPortrait:text-2xl lg:text-2xl px-8 sm:mobileLandscape:px-8 tabletPortrait:px-16 lg:pl-36 drop-shadow-lg mt-12 sm:mobileLandscape:mt-12 tabletPortrait:mt-12 lg:mt-12 opacity-0 fade-in">
                                    {t("contact-vacation-title")}
                                </p>
                                <div className="w-auto h-auto flex flex-row justify-start px-8 sm:mobileLandscape:px-8 tabletPortrait:px-16 lg:px-36 items-center mt-6 sm:mobileLandscape:mt-6 tabletPortrait:mt-6 lg:mt-6 opacity-0 fade-in">
                                    <FontAwesomeIcon
                                        className="w-5 sm:mobileLandscape:w-5 tabletPortrait:w-6 lg:w-6 h-5 sm:mobileLandscape:h-5 tabletPortrait:h-6 lg:h-6 mr-3 sm:mobileLandscape:mr-3 tabletPortrait:mr-3 lg:mr-3 text-white"
                                        icon={faUmbrellaBeach}
                                    />
                                    <p className="text-font text-white text-sm sm:mobileLandscape:text-sm tabletPortrait:text-base lg:text-base font-light drop-shadow-lg">
                                        <span className="font-light">{t("contact-vacation-time")}</span>
                                    </p>
                                </div>
                            </Fade>
                        </div>
                        <div className="w-64 sm:mobileLandscape:w-64 tabletPortrait:w-72 lg:w-72 h-64 sm:mobileLandscape:h-64 tabletPortrait:h-72 lg:h-72 shadow-lg mt-12 sm:mobileLandscape:mt-12 tabletPortrait:mt-12 lg:mt-12 px-8 sm:mobileLandscape:px-8 tabletPortrait:px-16 lg:px-36">
                            <iframe
                                title="Ochsen Location"
                                className="w-64 sm:mobileLandscape:w-64 tabletPortrait:w-72 lg:w-72 h-64 sm:mobileLandscape:h-64 tabletPortrait:h-72 lg:h-72"
                                frameborder="0"
                                src="https://maps.google.com/maps?width=400&amp;height=400&amp;hl=en&amp;q=Bahnhofstrasse 18 Brunnen&amp;t=&amp;z=16&amp;ie=UTF8&amp;iwloc=B&amp;output=embed"
                            ></iframe>
                        </div>
                    </div>
                </div>

                <Footer />
            </div>
        </>
    );
}
