import Information from "../Information";
import useSlides from "./useSlides";
import { useState, useEffect } from "react";
import useWindowSize from "@rooks/use-window-size";
import { useTranslation } from "react-i18next";
import Footer from "../Footer";

export default function Menu() {
    const slides = useSlides();
    const { innerWidth, innerHeight } = useWindowSize();
    const isPortrait = innerWidth < innerHeight;
    const [currentSlide, setCurrentSlide] = useState(0);
    const [slidesPerView, setSlidesPerView] = useState(1);
    const [previousIsPortrait, setPreviousIsPortrait] = useState(isPortrait);
    const [currentPage, setCurrentPage] = useState(1);
    const { t } = useTranslation();

    const handleShowLastClick = () => {
        if (currentSlide > 0) {
            setCurrentSlide(currentSlide - slidesPerView);
        }
    };

    const handleShowNextClick = () => {
        if (currentSlide + slidesPerView < slides.length) {
            setCurrentSlide(currentSlide + slidesPerView);
        }
    };

    useEffect(() => {
        function handleResize() {
            setSlidesPerView(innerWidth <= 550 ? 1 : 2);
            setCurrentSlide((currentPage - 1) * slidesPerView);
        }

        const handleOrientationChange = () => {
            setPreviousIsPortrait(isPortrait);
        };

        window.addEventListener("resize", handleResize);
        window.addEventListener("orientationchange", handleOrientationChange);

        handleResize();

        return () => {
            window.removeEventListener("resize", handleResize);
            window.removeEventListener("orientationchange", handleOrientationChange);
        };
    }, [innerWidth, isPortrait, slidesPerView, currentPage]);

    useEffect(() => {
        if (previousIsPortrait && !isPortrait && currentPage % slidesPerView === 0) {
            setCurrentPage(1);
            setCurrentSlide(0);
        }
    }, [isPortrait, previousIsPortrait, slidesPerView, currentPage]);

    return (
        <>
            <div className="w-auto h-auto flex flex-col">
                {/* Speisekarte */}
                <div className="wood-1 relative w-full h-auto flex flex-col z-20">
                    <p className="titles text-white text-2xl sm:mobileLandscape:text-2xl tabletPortrait:text-3xl lg:text-3xl p-8 sm:mobileLandscape:p-8 tabletPortrait:px-16 lg:px-80 text-center drop-shadow-lg my-8 sm:mobileLandscape:my-8 tabletPortrait:my-8 lg:my-8 z-10">
                        {t("menu-title")}
                    </p>
                    <div className="w-full h-auto bigDesktop:max-w-[1925px] bigDesktop:max-h-[1085px] bigDesktop:m-auto flex flex-col">
                        <div className="w-auto h-auto flex-col">
                            <div className="w-full sm:mobileLandscape:w-8/12 tabletPortrait:w-[85%] lg:w-[65%] h-auto flex flex-row px-8 sm:mobileLandscape:px-0 sm:mobileLandscape:mx-auto tabletPortrait:px-0 tabletPortrait:mx-auto lg:px-0 lg:mx-auto mb-4 sm:mobileLandscape:mb-4 tabletPortrait:mb-4 lg:mb-4 justify-center items-center">
                                {slides.slice(currentSlide, currentSlide + slidesPerView).map((slide) => (
                                    <div className="w-auto h-auto" key={slide.key}>
                                        {slide.content}
                                    </div>
                                ))}
                            </div>
                            <div className="w-full sm:mobileLandscape:w-8/12 tabletPortrait:w-[85%] lg:w-[65%] h-auto flex flex-row justify-between mb-20 sm:mobileLandscape:mb-20 tabletPortrait:mb-20 lg:mb-20 px-8 sm:mobileLandscape:px-0 sm:mobileLandscape:mx-auto tabletPortrait:px-0 tabletPortrait:mx-auto lg:px-0 lg:mx-auto">
                                <button
                                    className="w-24 sm:mobileLandscape:w-24 tabletPortrait:w-24 lg:w-24 h-9 sm:mobileLandscape:h-9 tabletPortrait:h-9 lg:h-9 bg-transparent p-1 sm:mobileLandscape:p-1 tabletPortrait:p-1 lg:p-1 cursor-pointer border-2 sm:mobileLandscape:border-2 drop-shadow-lg rounded-tr-lg rounded-bl-lg"
                                    onClick={handleShowLastClick}
                                    disabled={currentSlide === 0}
                                >
                                    <p className="titles text-white text-base sm:mobileLandscape:text-base tabletPortrait:text-base lg:text-base drop-shadow-lg">
                                        {t("menu-back")}
                                    </p>
                                </button>
                                <button
                                    className="w-24 sm:mobileLandscape:w-24 tabletPortrait:w-24 lg:w-24 h-9 sm:mobileLandscape:h-9 tabletPortrait:h-9 lg:h-9 bg-transparent p-1 sm:mobileLandscape:p-1 tabletPortrait:p-1 lg:p-1 cursor-pointer rounded-br-lg rounded-tl-lg border-2 sm:mobileLandscape:border-2 drop-shadow-lg"
                                    onClick={handleShowNextClick}
                                    disabled={currentSlide + slidesPerView >= slides.length}
                                >
                                    <p className="titles text-white text-base sm:mobileLandscape:text-base tabletPortrait:text-base lg:text-base drop-shadow-lg">
                                        {t("menu-next")}
                                    </p>
                                </button>
                            </div>
                        </div>
                    </div>
                </div>

                <Information />
                <Footer />
            </div>
        </>
    );
}
