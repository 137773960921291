"use client";
import { useEffect } from "react";
import FadeInAnimation from "./FadeInAnimation";

function Fade({ children }) {
    useEffect(() => {
        FadeInAnimation();
    }, []);

    return <>{children}</>;
}

export default Fade;
