import { useState } from "react";
import m1 from "../../images/menu-01.webp";
import m2 from "../../images/menu-02.webp";
import m3 from "../../images/menu-03.webp";
import m4 from "../../images/menu-04.webp";
import m5 from "../../images/menu-05.webp";
import m6 from "../../images/menu-06.webp";
import m7 from "../../images/menu-07.webp";
import m8 from "../../images/menu-08.webp";
import m9 from "../../images/menu-09.webp";
import m10 from "../../images/menu-10.webp";
import m11 from "../../images/menu-11.webp";
import m12 from "../../images/menu-12.webp";
import m13 from "../../images/menu-13.webp";
import m14 from "../../images/menu-14.webp";

const useSlides = () => {
    const [slides] = useState([
        {
            key: "slide-1",
            content: <img src={m1} quality={100} priority alt="Menu" placeholder="blur" />,
        },
        {
            key: "slide-2",
            content: <img src={m2} quality={100} priority alt="Menu" placeholder="blur" />,
        },
        {
            key: "slide-3",
            content: <img src={m3} quality={100} priority alt="Menu" placeholder="blur" />,
        },
        {
            key: "slide-4",
            content: <img src={m4} quality={100} priority alt="Menu" placeholder="blur" />,
        },
        {
            key: "slide-5",
            content: <img src={m5} quality={100} priority alt="Menu" placeholder="blur" />,
        },
        {
            key: "slide-6",
            content: <img src={m6} quality={100} priority alt="Menu" placeholder="blur" />,
        },
        {
            key: "slide-7",
            content: <img src={m7} quality={100} priority alt="Menu" placeholder="blur" />,
        },
        {
            key: "slide-8",
            content: <img src={m8} quality={100} priority alt="Menu" placeholder="blur" />,
        },
        {
            key: "slide-9",
            content: <img src={m9} quality={100} priority alt="Menu" placeholder="blur" />,
        },
        {
            key: "slide-10",
            content: <img src={m10} quality={100} priority alt="Menu" placeholder="blur" />,
        },
        {
            key: "slide-11",
            content: <img src={m11} quality={100} priority alt="Menu" placeholder="blur" />,
        },
        {
            key: "slide-12",
            content: <img src={m12} quality={100} priority alt="Menu" placeholder="blur" />,
        },
        {
            key: "slide-13",
            content: <img src={m13} quality={100} priority alt="Menu" placeholder="blur" />,
        },
        {
            key: "slide-14",
            content: <img src={m14} quality={100} priority alt="Menu" placeholder="blur" />,
        },
    ]);

    return slides;
};

export default useSlides;
