import { useContext } from "react";
import { useTranslation } from "react-i18next";
import LocaleContext from "../LocaleContext";

export default function LanguageChanger() {
    const { i18n } = useTranslation();
    const currentLocale = i18n.language;
    const { locale, setLocale } = useContext(LocaleContext);

    const handleChange = (event) => {
        const selectedLocale = event.target.value;
        if (locale !== selectedLocale) {
            i18n.changeLanguage(selectedLocale);
            setLocale(selectedLocale);
        }
    };

    return (
        <>
            <select
                className="w-auto h-auto px-1 cursor-pointer bg-white border focus:outline-none titles text-lg sm:mobileLandscape:text-lg tabletPortrait:text-2xl lg:text-xl text-black"
                onChange={handleChange}
                value={currentLocale}
            >
                <option value="en">English</option>
                <option value="de">German</option>
            </select>
        </>
    );
}
