import Information from "../Information";
import p1 from "../../images/poulet.webp";
import Fade from "../Fade";
import { useTranslation } from "react-i18next";
import Footer from "../Footer";

export default function Poulet() {
    const { t } = useTranslation();
    return (
        <>
            <div className="w-auto h-auto flex flex-col">
                {/* Poulet 1 */}
                <div className="wood-1 relative w-full h-auto lg:min-h-screen flex flex-col z-20">
                    <p className="titles text-white text-2xl sm:mobileLandscape:text-2xl tabletPortrait:text-3xl lg:text-3xl p-8 sm:mobileLandscape:p-8 tabletPortrait:px-16 lg:px-80 text-center drop-shadow-lg mt-8 sm:mobileLandscape:mt-8 tabletPortrait:mt-8 lg:mt-8 z-10">
                        {t("poulet-title")}
                    </p>
                    <div className="w-full h-auto bigDesktop:max-w-[1925px] bigDesktop:max-h-[1085px] bigDesktop:m-auto flex flex-col">
                        <div className="w-full h-auto flex flex-col sm:mobileLandscape:flex-row tabletPortrait:flex-col lg:flex-row">
                            <div className="w-full h-auto flex flex-col justify-center items-start">
                                <Fade>
                                    <p className="text-font text-white text-sm sm:mobileLandscape:text-sm tabletPortrait:text-base lg:text-base font-bold px-8 sm:mobileLandscape:px-8 tabletPortrait:pl-16 tabletPortrait:pr-44 lg:pl-36 lg:pr-28 desktop:pr-56 bigDesktop:pr-96 drop-shadow-lg mt-8 sm:mobileLandscape:mt-8 tabletPortrait:mt-8 lg:mt-8 desktop:mt-20 opacity-0 fade-in">
                                        {t("poulet-text1")}
                                    </p>
                                    <p className="text-font text-white text-sm sm:mobileLandscape:text-sm tabletPortrait:text-base lg:text-base font-bold px-8 sm:mobileLandscape:px-8 tabletPortrait:pl-16 tabletPortrait:pr-44 lg:pl-36 lg:pr-28 desktop:pr-56 bigDesktop:pr-96 drop-shadow-lg mt-3 sm:mobileLandscape:mt-3 tabletPortrait:mt-3 lg:mt-3 opacity-0 fade-in">
                                        {t("poulet-text2")}
                                    </p>
                                    <p className="text-font text-white text-sm sm:mobileLandscape:text-sm tabletPortrait:text-base lg:text-base font-bold px-8 sm:mobileLandscape:px-8 tabletPortrait:pl-16 tabletPortrait:pr-44 lg:pl-36 lg:pr-28 desktop:pr-56 bigDesktop:pr-96 drop-shadow-lg mt-3 sm:mobileLandscape:mt-3 tabletPortrait:mt-3 lg:mt-3 opacity-0 fade-in">
                                        {t("poulet-text3")}
                                    </p>
                                    <p className="text-font text-white text-sm sm:mobileLandscape:text-sm tabletPortrait:text-base lg:text-base font-bold px-8 sm:mobileLandscape:px-8 tabletPortrait:pl-16 tabletPortrait:pr-44 lg:pl-36 lg:pr-28 desktop:pr-56 bigDesktop:pr-96 drop-shadow-lg mt-3 sm:mobileLandscape:mt-3 tabletPortrait:mt-3 lg:mt-3 mb-10 sm:mobileLandscape:mb-20 tabletPortrait:mb-20 lg:mb-20 opacity-0 fade-in"></p>
                                </Fade>
                            </div>
                            <div className="flex justify-center items-center max-w-96 sm:mobileLandscape:w-[32rem] tabletPortrait:w-[32rem] lg:min-w-[28rem] bigDesktop:min-w-[32rem] h-80 sm:mobileLandscape:h-[20rem] tabletPortrait:h-[32rem] lg:h-[32rem] bigDesktop:h-[34rem] mx-8 sm:mobileLandscape:ml-0 sm:mobileLandscape:mr-8 tabletPortrait:mx-auto lg:ml-0 lg:mr-36 mb-16 sm:mobileLandscape:mb-8 sm:mobileLandscape:mt-8 lg:mt-8 desktop:mt-32 bigDesktop:mt-44 tabletPortrait:mb-8 lg:mb-16 bigDesktop:mb-44 overflow-hidden">
                                <div className="w-72 sm:mobileLandscape:w-64 tabletPortrait:w-96 lg:w-96 bigDesktop:w-[28rem] h-auto z-0 drop-shadow-lg">
                                    <img
                                        className="poulet-img rounded-br-[50px] rounded-tl-[50px]"
                                        src={p1}
                                        alt="Poulet 1"
                                        placeholder="blur"
                                    />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <Information />
                <Footer />
            </div>
        </>
    );
}
