"use client";
import { useTranslation } from "react-i18next";

export default function Footer() {
    const { t } = useTranslation();
    return (
        <>
            {/* Footer */}
            <div className="w-full h-auto bigDesktop:max-w-[1925px] bigDesktop:max-h-[1085px] bigDesktop:m-auto flex flex-col mt-16 sm:mobileLandscape:mt-16 tabletPortrait:mt-16 lg:mt-16 bigDesktop:mt-16 justify-center items-center">
                <hr className="w-5/6 sm:mobileLandscape:w-5/6 tabletPortrait:w-5/6 lg:w-5/6 bigDesktop:w-11/12 h-auto brightness-50 text-white" />
                <div className="w-full h-auto flex flex-col-reverse sm:mobileLandscape:flex-row tabletPortrait:flex-row lg:flex-row justify-center sm:mobileLandscape:justify-between tabletPortrait:justify-between lg:justify-between items-center">
                    <p className="text-font text-white text-xs sm:mobileLandscape:text-xs tabletPortrait:text-xs lg:text-xs font-light px-8 sm:mobileLandscape:pl-16 sm:mobileLandscape:pr-0 tabletPortrait:pl-16 tabletPortrait:pr-0 lg:pl-36 lg:pr-0 drop-shadow-lg mt-3 sm:mobileLandscape:mt-4 tabletPortrait:mt-4 lg:mt-6 mb-6 sm:mobileLandscape:mb-4 tabletPortrait:mb-4 lg:mb-6">
                        {t("footer-left")}
                    </p>
                    <p className="text-font text-white text-xs sm:mobileLandscape:text-xs tabletPortrait:text-xs lg:text-xs font-light px-8 sm:mobileLandscape:pr-16 sm:mobileLandscape:pl-0 tabletPortrait:pr-16 tabletPortrait:pl-0 lg:pr-36 lg:pl-0 drop-shadow-lg mt-6 sm:mobileLandscape:mb-4 tabletPortrait:mb-4 lg:mb-6 sm:mobileLandscape:mt-4 tabletPortrait:mt-4 lg:mt-6">
                        {t("footer-right")}
                        <span className="font-bold underline underline-offset-2">
                            <a
                                className="no-underline"
                                href="https://linktr.ee/ahmedmalanovic"
                                target="_blank"
                                rel="noopener noreferrer"
                            >
                                Ahmed Malanović
                            </a>
                        </span>
                    </p>
                </div>
            </div>
        </>
    );
}
